<template>
  <div class="layout relative">
    <div class="content">
      <router-view />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('common', ['isMobile'])
  }
}
</script>
